import { Fab } from '@mui/material';
import { useAuth } from '@mindhiveoy/react-auth';
import { useMemo } from 'react';
import CookieIcon from '@mui/icons-material/Cookie';
import styled from '@emotion/styled';
import type { CSSProperties } from 'react';

const StyledFab = styled(Fab)`
  position: fixed;
  bottom: 1.250em;
  left: 1.250em;
  z-index: 1000;
`;

const CookieFAB = () => {
  const {
    user,
  } = useAuth();

  const style: CSSProperties = useMemo(() => ({
    opacity: user ? 0 : 0.9,
    pointerEvents: user ? 'none' : 'auto',
  }), [user,]);

  return <StyledFab
    id="cookie-setting-button"
    size='small'
    color="primary"
    aria-label="cookie-setting"
    data-cc="c-settings"
    style={style}
  >
    <CookieIcon />
  </StyledFab>;
};
export default CookieFAB;
